import Format from "../../utils/format";
import ButtonIcon from "../buttonicon";
import { CompanyTableContainer, EmptyListNotice, ResponsiveDataContainer, ResponsiveLabel, TableData, TableHead, TableHeadRow, TableRow, TableTitle } from "./style";

function CompanyTable({ companies, handleRowClick }){


  return(
    <CompanyTableContainer>

      <TableHeadRow>
        
        <TableHead>
          Código
        </TableHead>

        <TableHead>
          Nome
        </TableHead>

        <TableHead>
          CNPJ
        </TableHead>

      </TableHeadRow>

      
      {companies.map((company, index) => (
        <TableRow 
          onClick={() => handleRowClick(company)}
          key={company.codigoEmpresa}
          whiteRow={index % 2 === 0}
        >
          <TableData smaller>
            {company.codigoEmpresa}
          </TableData>

          <TableData>
            {company.nomeFantasia}
          </TableData>

          <TableData>
            {Format.asCnpj(company.cnpj)}
          </TableData>

          <ResponsiveDataContainer>
            <ResponsiveLabel bold>
              {company.codigoEmpresa} - {company.nomeFantasia}
            </ResponsiveLabel>

            <ResponsiveLabel>
              {Format.asCnpj(company.cnpj)}
            </ResponsiveLabel>
          </ResponsiveDataContainer>

          <ButtonIcon
            src = "assets/edit.png" 
          />
        </TableRow>
      ))}
      
      
    </CompanyTableContainer>
  )
}

export default CompanyTable;