import React, { useCallback } from "react"
import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router";

import api from '../../services/api';

import FormContext from "../../contexts/formContext";
import CompanyItem from "./components/companyitem";
import GroupItem from "./components/groupitem";
import LabelledButton from "../../components/labelledbutton";

import {
  CompaniesContainer,
  GroupListContainer,
  HeadContainer,
  NoneCompanyItem,
  OptionsContainer,
  PageContainer,
  Row,
  SearchInput,
  Title
} from "./styles";
import AuthContext from "../../contexts/authContext";
import LoadingModal from "../../components/loadingmodal";
import { Fragment } from "react";
import UrlTestModal from "../../components/urltestmodal";
import StoragedList from "../../storage/list";
import ApiConfigContext from "../../contexts/apiConfigContext";
import CompanySignUpForm from "../signup/components/companysignupform"
import GroupPicker from "../../components/grouppicker";
import { response } from "msw";
import ResponseModal from "../../components/responsemodal";
import KeysModal from "../../components/keysmodal";



function GroupList() {
  const [groupList, setGroupList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [urlToTest, setUrlToTest] = useState(null);
  const [dataToShow, setDataToShow] = useState([]);
  const [isCompanySignUpFormVisible, setIsCompanySignUpFormVisible] = useState(false);
  const [isGroupPickerVisible, setIsGroupPickerVisible] = useState(false);
  const [newCompanyGroup, setNewCompanyGroup] = useState(null);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
  const [keysToShow, setKeysToShow] = useState(null);

  const history = useHistory();
  
  const { 
    registerNewItem, 
    companyToUpdate, 
    selectCompany,
    selectGroup, 
  } = useContext(FormContext);

  const { token } = useContext(AuthContext);
  const { apiUrl } = useContext(ApiConfigContext);


  useEffect(() => {
    if(apiUrl) loadGroups();
    
  }, [apiUrl]);

  const loadGroups = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    try {
      setIsLoading(true)
      const response = await api.directTo(apiUrl).get('/getAllGroups', config);
      const list = response.data.data.map(item => ({ ...item, isCompaniesDisplayed: false }))
      StoragedList.setList(list)
      setGroupList(list);
      setDataToShow(list);
    } catch {
      setGroupList([]);
      setDataToShow([]);
      setIsErrorModalVisible(true);
      await new Promise(resolve => setTimeout(resolve, 2000));
      setIsErrorModalVisible(false);
      return;
    } finally {
      setIsLoading(false);
    }
  }


  const filterListByTerm = useCallback(term => {
    let result = [];
    if (term === '') return setDataToShow(groupList);
    groupList.forEach(i => {
      if (i.grupo.toUpperCase().includes(term.toUpperCase())) {
        result.push(i);
      }
    })
    setDataToShow(result);
  })



  const handleDropDownClick = (id) => {
    let newGroupsArray = groupList;
    newGroupsArray.map(group => {
      if (group.grupoEmpresa === id) {
        group.isCompaniesDisplayed = !group.isCompaniesDisplayed;
      }
      return group;
    })

    setGroupList([...newGroupsArray]);
  }

  const openTestModal = (url) => {
    setUrlToTest(url);
  }

  const closeTestModal = () => {
    setUrlToTest(null);
  }

  const openGroupEdit = (item) => {
    selectGroup(item);
    history.push('/cadastro');
  }

  const openSignUp = () => {
    history.push('/cadastro');
  }


  const openCompanyEdit = (company, group) => {
    setNewCompanyGroup(group);
    selectCompany(company);
    setIsCompanySignUpFormVisible(true);
  }




  function showCompanySignUpForm(group){
    setNewCompanyGroup(group);
    setIsCompanySignUpFormVisible(true);
  } 

  function closeCompanySignUpForm(){
    setIsCompanySignUpFormVisible(false);
  }



  function showKeysModal(company){
    setKeysToShow(company);
  } 

  function closeKeysModal(){
    setKeysToShow(false);
  }


  

  function pickGroup(group){
    hideGroupPicker();
    showCompanySignUpForm(group)
  }

  function showGroupPicker(){
    setIsGroupPickerVisible(true);
  }

  function hideGroupPicker(){
    setIsGroupPickerVisible(false);
  }


  return (
    <PageContainer hiddenOverflow={isLoading || !!urlToTest}>
      {isLoading &&
        <LoadingModal />
      }

      {urlToTest &&
        <UrlTestModal
          url={urlToTest}
          closeModal={closeTestModal}
        />
      }

     {isGroupPickerVisible &&
      <GroupPicker
        handleItemClick={pickGroup}
        closeModal={hideGroupPicker} 
      />
     }

     {isCompanySignUpFormVisible && 
        <CompanySignUpForm
          companyToUpdate={companyToUpdate}
          group={newCompanyGroup}
          closeModal={closeCompanySignUpForm}
          prevPageReload={loadGroups}
          licensedApps={newCompanyGroup.apps}
        />}
      
      {isErrorModalVisible &&
        <ResponseModal
          icon={'sad'}
          response={'Algo deu errado, tente recarregar a página.'} 
        /> 
      }

      {!!keysToShow && 
        <KeysModal 
          company={keysToShow}
          handleClose={closeKeysModal}
        />}

      <GroupListContainer>
        <HeadContainer>

          <Row>
            <Title>
              GRUPOS
            </Title>

            <OptionsContainer columns={2}>

              <LabelledButton
                src="assets/add.png"
                label="Grupo"
                handleClick={openSignUp}
              />

              <LabelledButton
                src="assets/add.png"
                label="Empresa"
                handleClick={showGroupPicker}
              />

            </OptionsContainer>
          </Row>
          <SearchInput
            type='text'
            placeholder='Pesquise por grupo'
            onChange={event => {
              filterListByTerm(event.target.value)
            }}
          />

        </HeadContainer>

        {dataToShow.map(group => (
          <Fragment key={group.grupoEmpresa}>
            <GroupItem
              group={group}
              handleDropDownClick={() => handleDropDownClick(group.grupoEmpresa)}
              handleEditClick={() => openGroupEdit(group)}
            />

            <CompaniesContainer
              display={group.isCompaniesDisplayed ? 'block' : 'none'}
              aria-label={`company-list-${group.grupoEmpresa}`}
              role='list'
            >
              {group.empresas.length > 0 ? group.empresas.map((company, index) => (
                <CompanyItem
                  company={company}
                  openEdit={() => openCompanyEdit(company, group)}
                  openKeys={() => showKeysModal(company)}
                  key={company.empresa.toString()}
                  index={index}
                  openTestModal={url => openTestModal(url)}
                />
              )) :
                <NoneCompanyItem>
                  Nenhuma empresa cadastrada
                </NoneCompanyItem>
              }
              <LabelledButton 
                src="assets/add.png"
                label="Empresa"
                handleClick={() => showCompanySignUpForm(group)}
              />
            </CompaniesContainer>
          </Fragment>
        ))}
      </GroupListContainer>
    </PageContainer>
  )
}

export default GroupList;