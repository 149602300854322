import React from 'react';
import { 
  Button, 
  LoginFormContainer, 
  LoginFormField, 
  LoginFormFieldLabel, 
  Error, 
  Logo} from './styles';
import { Formik } from 'formik';
import * as yup from 'yup';
import Loader from 'react-loader-spinner';

function LoginForm({ handleLogin, hasWrongDataError, isSubmitting }){
  
  const loginSchema = yup.object().shape({
    user: yup
      .string()
      .required('Campo não pode ficar vazio.'),
    pass: yup
      .string()
      .required("Insira a senha.")
  })

  return(
      <Formik
        initialValues = {{
          user: '',
          pass: '',
        }} 

        validationSchema = {loginSchema}
        validateOnBlur = {false}
        validateOnChange = {false}

        onSubmit = {(values) => {
          handleLogin(values);
        }}
      >
        {({handleChange, errors}) => (
          <LoginFormContainer>
            <Logo src = 'assets/hk-logo-azul.png'/>

            {hasWrongDataError && <Error centered>Usuário e/ou senha incorretos</Error>}

            <LoginFormFieldLabel htmlFor = 'user'>
              E-mail:
            </LoginFormFieldLabel>

            <LoginFormField
              type = 'text'
              id = 'user'
              name = 'user'
              onChange = {handleChange}
              placeholder = "Insira seu e-mail..."
              haserrors = {errors.user}  
            />
            {errors.user ? <Error role = "error">{errors.user}</Error> : null}

            <LoginFormFieldLabel htmlFor = 'pass'>
              Senha:
            </LoginFormFieldLabel>

            <LoginFormField
              id = 'pass'
              type = 'password'
              name = 'pass'
              onChange = {handleChange}
              placeholder = "Insira sua senha..." 
              haserrors = {errors.pass}
            />
            {errors.pass ? <Error role = "error">{errors.pass}</Error> : null}

            <Button type = "submit">

              {isSubmitting ?
              <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={10}
                width={20}
              />  
              : 'Acessar'}
            </Button>

          </LoginFormContainer>
        )}

      </Formik>
  )
}

export default LoginForm;