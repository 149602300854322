import { useContext } from "react";
import TestApiUrl from "../storage/testApiUrl";
import api from "./api";
import ApiConfigContext from "../contexts/apiConfigContext";
const DEFAULT_URL = 'https://api.grupohk.com.br:5003/api/v1/'
  ;

export default {

  getVersions: async () => {
    try {
      let url;
      const token = `Bearer ${sessionStorage.getItem('token')}`
      const storagedTestApiUrl = TestApiUrl.getUrl();
      url = storagedTestApiUrl ? storagedTestApiUrl : DEFAULT_URL;

      const config = {
        headers: {
          Authorization: token
        }
      };

      const response = await api.directTo(url).get('/getlogversoes', config);
      return response.data;
    } catch (error) {
      return {
        error: true,
        errorMessage: error
      }
    }
  },


  getChaves: async (empresa) => {
    try {
      let url;
      const token = `Bearer ${sessionStorage.getItem('token')}`
      const storagedTestApiUrl = TestApiUrl.getUrl();
      url = storagedTestApiUrl ? storagedTestApiUrl : DEFAULT_URL;

      const config = {
        headers: {
          Authorization: token
        }
      };

      const resposta = await api.directTo(url).get(`/chaves/${empresa}`, config);

      if (resposta.error) return { error: true, mensagem: resposta.message };

      return resposta.data;
    } catch (error) {
      return {
        error: true,
        mensagem: error
      }
    }
  },

  createUserSefip: async ({ user, pass, codGrupo }) => {
    try {
      let url;
      const token = `Bearer ${sessionStorage.getItem('token')}`
      const storagedTestApiUrl = TestApiUrl.getUrl();
      url = storagedTestApiUrl ? storagedTestApiUrl : DEFAULT_URL;

      const config = {
        headers: {
          Authorization: token
        }
      };

      const body = { user, pass, codGrupo };

      const resposta = await api.directTo(url).post(`/cadusersefip`, body, config);

      if (resposta.data.status !== 0) {
        return { error: true, message: resposta.data.mensagem };
      }


      return {
        error: false
      }
    } catch (error) {
      return {
        error: true,
        mensagem: error
      }
    }
  },

  /**
   * 
   * @param {*} user 
   * @param {'Disable' | 'Enable'} operation 
   * @returns 
   */

  disableEnableUser: async (user, operation) => {
    try {
      let url;
      const token = `Bearer ${sessionStorage.getItem('token')}`
      const storagedTestApiUrl = TestApiUrl.getUrl();
      url = storagedTestApiUrl ? storagedTestApiUrl : DEFAULT_URL;

      const config = {
        headers: {
          Authorization: token
        }
      };


      let resposta = {};

      if (operation === 'Disable') {
        resposta = await api.directTo(url).post(`/disableusersefip`, { user }, config);
      } else {
        resposta = await api.directTo(url).post(`/enableusersefip`, { user }, config);
      }



      if (resposta.data.status !== 0) {
        return { error: true, message: resposta.data.mensagem };
      }


      return {
        error: false
      }
    } catch (error) {
      return {
        error: true,
        mensagem: error
      }
    }
  },




  /**
   * 
   * @param {*} user 
   * @param {String} newPass 
   * @returns 
   */

  updatePassUserSefip: async (user, newPass) => {
    try {
      let url;
      const token = `Bearer ${sessionStorage.getItem('token')}`
      const storagedTestApiUrl = TestApiUrl.getUrl();
      url = storagedTestApiUrl ? storagedTestApiUrl : DEFAULT_URL;

      const config = {
        headers: {
          Authorization: token
        }
      };

      const resposta = await api.directTo(url).post(`/updatepassusersefip`, { user, newPass }, config);;

      if (resposta.data.status !== 0) {
        return { error: true, message: resposta.data.mensagem };
      }

      return {
        error: false
      }
    } catch (error) {
      return {
        error: true,
        mensagem: error
      }
    }
  },




  getUsersSefip: async (codGrupo) => {
    try {
      let url;
      const token = `Bearer ${sessionStorage.getItem('token')}`
      const storagedTestApiUrl = TestApiUrl.getUrl();
      url = storagedTestApiUrl ? storagedTestApiUrl : DEFAULT_URL;

      const config = {
        headers: {
          Authorization: token
        }
      };



      const resposta = await api.directTo(url).get(`/getuserssefip/${codGrupo}`, config);

      if (resposta.data.status !== 0) {
        return { error: true, message: resposta.data.mensagem };
      }


      return {
        error: false,
        data: resposta.data
      }
    } catch (error) {
      return {
        error: true,
        mensagem: error
      }
    }
  },


}